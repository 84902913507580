// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

export const version = '1.0';

export { default as namespace } from './namespace';
export { default as createComponent } from './createComponent';
export { default as onReady, priority } from './onReady';
export { default as addStatusMessage } from './addStatusMessage';
export { default as clearStatusMessages } from './clearStatusMessages';
export { default as showInternalError } from './showInternalError';
export { default as prepareUrl } from './prepareUrl';
export { default as addUrlParams } from './addUrlParams';
export { default as render } from './render';
export { default as createElement } from './createElement';
export { default as escapeAttribute } from './escapeAttribute';
export { default as escapeHtml } from './escapeHtml';
export { default as emptyFn } from './emptyFn';
export { default as fireCustomEvent } from './fireCustomEvent';
export { default as keyCode } from './keyCode';

export { BrowserFeatures } from './browser-features';
export { Component, registerComponent, getComponent } from './component';
export { default as Locale } from './Locale';
export { Hint } from './hint';
export { Button } from './button';
export { SmallButton } from './small-button';
export { BigButton } from './big-button';
export { SplitButton } from './split-button';
export { CommandButton } from './command-button';
export { CustomButton } from './custom-button';

export { FormRedirect, submit, redirect, redirectPost } from './form-redirect';

import { DisplayField, Values } from './form';
export const form = {
    DisplayField,
    Values,
};

export { FormAjax } from './form-ajax';
export { Container } from './container';
export { ListContainer } from './list-container';

import { TwoColumns } from './layout';
export const layout = {
    TwoColumns,
};

export { default as Cookie } from 'common/cookie';
export { Panel } from './panel';

export { messageBox, MessageBox, AjaxMessageBox, MultiCheckboxMessageWindow, WebspaceFoldersMessageBox } from './message-box';

export { ListContextMenu } from './list-context-menu';
export { DropdownHeader } from './dropdown-header';
export { DropdownManager } from './dropdown-manager';
export { SmallTools } from './small-tools';
export { CollapsibleBlock } from './collapsible-block';
export { Pathbar } from './pathbar';

export { Bar, bar } from './bar';

export { DynamicSubForm, AddRemoveDynamicSubForm } from './dynamic-sub-form';

export { Box } from './box';
export { Gauge } from './gauge';
export { Tooltip } from './tooltip';
export { ModalDialogBox } from './modal-dialog-box';
export { PopupForm } from './popup-form';
export { AjaxPopupForm } from './ajax-popup-form';
export { Popup } from './popup';
export { DoubleListSelector } from './double-list-selector';
export { EnhancedDoubleListSelector } from './enhanced-double-list-selector';
export { ImageSelector } from './image-selector';

export { ToolList, toollist } from './tool-list';

export { DynamicPopupHint } from './dynamic-popup-hint';
export { PasswordMeter } from './password-meter';
export { PasswordGenerator } from './password-generator';
export { UpgradeFailureNotification } from './upgrade-failure-notification';
export { WebserverConfigurationFailure } from './webserver-configuration-failure';
export { CustomDescription } from './custom-description';
export { ConfirmationPopupManager } from './confirmation-popup-manager';
export { LookUp } from './lookup';
export { StatusMessage } from './status-message';
export { Observer } from './observer';

import { ProgressBar } from './task-progress-bar';
import { StatusMessage } from './task-status-message';
export const Task = {
    ProgressBar,
    StatusMessage,
};

export { ScheduledTasks } from './scheduled-tasks';
export { ActiveList } from './active-list';

import { Tree } from './file-manager/tree';
import { UploadAdapterIframe } from './file-manager/upload-adapter-iframe';
export const FileManager = {
    Tree,
    UploadAdapterIframe,
};

export { DirectoryBrowser } from './directory-browser';
export { DropArea } from './drop-area';
export { GroupsList } from './groups-list';
export { ReporterPopup } from './issue-reporter';
export { ObjectSwitcher } from './object-switcher';
export { PopupSlider } from './popup-slider';
export { ScrollableList } from './scrollable-list';
export { UAT } from './uat';

import { List, list } from './list';
import { Filters } from './list/filters';
import { Domains } from './list/domains';
List.Filters = Filters;
list.Domains = Domains;
export { List, list };

export { default as getControlPanelLoginHtml } from './getControlPanelLoginHtml';
export { default as api } from './api';

export { default as pleskWS } from './pleskWS';
