// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import api from './api';

/**
 * @class Locale
 */
class Locale {
    static #sections = {};

    /**
     * Register new locale section
     * @param {String} name
     * @param {Object} [messages]
     * @returns {Locale}
     */
    static addSection(name, messages) {
        this.#sections[name] = new Locale(messages);
        return this.#sections[name];
    }

    /**
     * Returns registered locale section or created empty section
     * @param {String} name
     * @returns {Locale}
     */
    static getSection(name) {
        if (!this.#sections[name]) {
            this.#sections[name] = new Locale();
        }
        return this.#sections[name];
    }

    /**
     * Load, register, and returns locale sections
     * @param {String[]} names
     * @returns {Promise<Locale[]>}
     */
    static async loadSections(names) {
        const namesToLoad = names.filter(name => !this.#sections[name]);

        if (namesToLoad.length) {
            await api.get(`/cp/locale?${namesToLoad.map(section => `sections[]=${encodeURIComponent(section)}`).join('&')}`)
                .then(sections => {
                    Object.keys(sections).forEach(name => {
                        this.addSection(name, sections[name]);
                    });
                });
        }

        return names.map(name => this.#sections[name]);
    }

    /**
     * Load, register, and returns locale section
     * @param {String} name
     * @returns {Promise<Locale>}
     */
    static async loadSection(name) {
        const [locale] = await this.loadSections([name]);
        return locale;
    }

    constructor(messages = {}) {
        this.messages = messages;
    }

    /**
     * Get locale message by key.
     * @param {String} key
     * @param {Object} [params]
     * @returns {String}
     */
    lmsg(key, params) {
        const value = this.messages[key];
        if ('undefined' === typeof value) {
            return `[${key}]`;
        }

        if ('undefined' === typeof params) {
            return value;
        }

        return Object.keys(params)
            .reduce((value, paramKey) => value.replace(`%%${paramKey}%%`, params[paramKey]), value);
    }
}

export default Locale;
